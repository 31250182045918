import {
    GET_DPO_TOKEN,
    DPO_TOKEN_SUCCESS,
    DPO_TOKEN_LOADER,
    HIDE_DPO_TOKEN_ERROR_MESSAGE,
    DPO_TOKEN_ERROR_MESSAGE,
    DPO_TOKEN_RESET,
    DPO_TOKEN_STATUS
  } from "constants/ActionTypes";

  import { httpPost } from "util/REST";
  
  
  export const getDpoTokenData = (policyNumber, amount) => {
    return async function (dispatch) {
        dispatch({ type: DPO_TOKEN_LOADER });
        httpPost(`createDpoToken/?policyNumber=${policyNumber}&amount=` + amount)
          .then(data => {
            if (data.status === 200 && data.response.transToken) {
              console.log("status: ", data.status)
              //dispatch({ type: HIDE_DPO_TOKEN_LOADER })
              dispatch({ type: DPO_TOKEN_STATUS, payload: data.status })
              dispatch({ type: DPO_TOKEN_SUCCESS, payload:  data.response.transToken });
            } else {
              //dispatch({ type: HIDE_DPO_TOKEN_LOADER })
              dispatch({ type: DPO_TOKEN_STATUS, payload: data.status })
              dispatch({ type: DPO_TOKEN_ERROR_MESSAGE, payload: data.response.resultExplanation ? data.repsonse.resultExplanation : "" })
            }
          })
          .catch(error => {
            //dispatch({ type: HIDE_DPO_TOKEN_LOADER })
            dispatch({ type: DPO_TOKEN_ERROR_MESSAGE, payload: error.message })
          })
      }
  };

  export const getDpoTokenLoader = () => {
    return {
        type: DPO_TOKEN_LOADER
    }
  }

  export const getDpoTokenSuccess = (data) => {
    return {
        type: DPO_TOKEN_SUCCESS,
        payload: data
    }
  }

  export const getDpoTokenError = (data) => {
    return {
        type: DPO_TOKEN_ERROR_MESSAGE,
        payload: data
    }
  }

  export const getHideDpoTokenError = () => {
    return {
        type: HIDE_DPO_TOKEN_ERROR_MESSAGE
    }
  }
  
  export const requestDpoTokenReset = () => {
    return {
      type: DPO_TOKEN_RESET
    };
  };

  /*
  export const getDpoTokenData = (policyNumber, amount) => {
    return async function (dispatch) {
      dispatch({ type: DPO_TOKEN_LOADER });
      httpPost(`createDpoToken/?policyNumber=${policyNumber}&amount=` + amount)
        .then(data => {
          if (data.status === 200 && data.response.transToken) {
            console.log("status: ", data.status)
            //dispatch({ type: HIDE_DPO_TOKEN_LOADER })
            dispatch({ type: DPO_TOKEN_STATUS, payload: data.status })
            dispatch({ type: DPO_TOKEN_SUCCESS, payload:  data.response.transToken });
          } else {
            //dispatch({ type: HIDE_DPO_TOKEN_LOADER })
            dispatch({ type: DPO_TOKEN_STATUS, payload: data.status })
            dispatch({ type: DPO_TOKEN_ERROR_MESSAGE, payload: data.response.resultExplanation ? data.repsonse.resultExplanation : "" })
          }
        })
        .catch(error => {
          //dispatch({ type: HIDE_DPO_TOKEN_LOADER })
          dispatch({ type: DPO_TOKEN_ERROR_MESSAGE, payload: error.message })
        })
    }
  };
  */