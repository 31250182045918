/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import moment from "moment";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  CircularProgress,
} from "@material-ui/core";

const TransactionHistory = ({ data, loading }) => {
  //setPayHistory(data.at(arrayIndex));
  //console.log("pay history: ",payHistory);
  console.log("pay history: ",data)

  return (
    <div
      className="custom-service-data-table table-responsive-material "
      style={{ width: "100%" }}
    >
      <Table className="table-bordered">
        <TableHead>
          <TableRow>
            {/* <TableCell align="center">Policy Code</TableCell> */}
            <TableCell className="tablecell-heading" align="center">
              Date
            </TableCell>
            <TableCell className="tablecell-heading" align="center">
              DPO Ref
            </TableCell>
            <TableCell className="tablecell-heading" align="center">
              Paid Amount
            </TableCell>
            <TableCell className="tablecell-heading" align="center">
              Cash Office Receipt
            </TableCell>
            <TableCell className="tablecell-heading" align="center">
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data ? (
            data
              //.slice(0)
              .reverse()
              .map((i, index) => {
                return (
                  <TableRow key={index}>
                    {/* <TableCell align="center">{i.policyCode} </TableCell> */}
                    <TableCell align="center">
                      {moment(i.serviceDate).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell align="left">{i.transactionReference}</TableCell>
                    <TableCell align="center">{i.amount}</TableCell>
                    <TableCell align="center">{i.receipt}</TableCell>
                    <TableCell align="center">{i.flag}</TableCell>
                  </TableRow>
                );
              })
          ) : loading ? (
            <TableRow>
              <TableCell colSpan="4" align="center">
                Loading...
              </TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell colSpan="4" align="center">
                No record found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default TransactionHistory;
