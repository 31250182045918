/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { httpGet } from "util/REST";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import BasicInfo from "./BasicInfo";
import BeneficiariesInfo from "./BeneficiariesInfo";
import ControlledOpenSelect from "./DropDown";
import ContainerHeader from "../../../../../components/ContainerHeader/index";
import { useDispatch, useSelector } from "react-redux";
import {
  getAgentInfo,
  getBasicInfo,
  getBenifitInfo,
  getPartyInfo,
  getFinancialInfo,
  getClaimInfo,
  getCustomerServiceInfo,
  getPaymentInfo,
  resetPolicydetails,
} from "actions/PolicyDetails";
import { hideErrorMessage } from "actions/Common";
import { ToastContainer } from "react-toastify";
import { ErrorToaster } from "util/ReactToastify";

import PremiumStatement from "./PremiumStatement";

import { CircularProgress } from "@material-ui/core";
import FinancialInformation from "./FinancialInformation";
import BenefitInformation from "./BenefitInformation";
import ClaimInformation from "./ClaimInformation";
import CustomerServiceInfo from "./CustomerServiceInfo";
import YearsDropdown from "./YearsDropdown";
import TransactionHistory from "./TransactionHistory";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
});

const ControlledExpansionPanels = (props) => {
  const dispatch = useDispatch();

  let dropDownDataShare = props.location
    ? props.location.state.dropDownData
    : null;
  console.log("Drop down data: ", dropDownDataShare);

  const {
    agentInfoData,
    basicInfoData,
    benifitInfoData,
    financialInfoData,
    partyInfoData,
    claimInfoData,
    customerServiceInfoData,
    getPaymentInfoDetails,
  } = useSelector(({ policyDetails }) => policyDetails);

  const { loading, message, error } = useSelector(
    ({ commonData }) => commonData
  );

  const [expanded, setExpanded] = useState("panel1");
  const {
    classes,
    match,
    location: { state },
  } = props;
  const [policyNumber, setPolicyNumber] = useState("");
  const [year, setYear] = React.useState("");
  const [historyData, setHistoryData] = useState([]);
  const [payHistory, setPayHistory] = useState();
  const [loadingCircle, setLoadingCircle] = useState(false);

  const omang = localStorage.getItem("omangNumber")
    ? JSON.parse(localStorage.getItem("omangNumber"))
    : null;

  const getPaymentHistory = async () => {
    setLoadingCircle(true);

    await httpGet(`getPaymentHistory/customer?IDNumber=${omang}`)
      .then((data) => {
        const dataResponse = data;
        console.log("customer data: ", dataResponse);
        setHistoryData(data);
        console.log("transaction data: ", historyData);

        //setIndex(dropDownDataShare.findIndex(`${policyNumber}`,0));
        //console.log("index: ",index)
        console.log("drop down data: ",dropDownDataShare)
        let arrayIndex = dropDownDataShare.findIndex((object) => {
          return object.policyNumber === `${policyNumber}`;
        });

        if (dataResponse.length === 0) {
          setLoadingCircle(false);
        } else {
          //let theArray = dataResponse[arrayIndex];
          let theArray = dataResponse.filter((item) => item.onlinepaymentcollection[0].policyNumber === policyNumber)
          setPayHistory(theArray[0].onlinepaymentcollection);
          console.log("array: ", theArray[0].onlinepaymentcollection);
          console.log("pay history: ", payHistory);
          setLoadingCircle(false);
        }
      })
      .catch((error) => {
        setLoadingCircle(false);
        console.log(error);
      });
  };

  const handleChange = (panel) => (event, expanded) => {
    setExpanded(expanded ? panel : false);

    if (expanded && policyNumber) {
      if (panel === "panel1") {
        dispatch(getBasicInfo(policyNumber));
        dispatch(getAgentInfo(policyNumber));
      } else if (panel === "panel2") {
        dispatch(getBenifitInfo(policyNumber));
      } else if (panel === "panel3") {
        dispatch(getPartyInfo(policyNumber));
      } else if (panel === "panel4") {
        dispatch(getFinancialInfo(policyNumber));
      } else if (panel === "panel5") {
        dispatch(getClaimInfo(policyNumber));
      } else if (panel === "panel6") {
        dispatch(getCustomerServiceInfo(policyNumber));
      } else if (panel === "panel8") {
        setPayHistory()
        getPaymentHistory();
      }
    }
  };

  useEffect(() => {
    if (!state || !state.policyNumber) {
      props.history.push("/home/my-policies");
    }
  }, [props.history]);

  useEffect(() => {
    let policyNumber = null;

    if (state && state.policyNumber) {
      setPolicyNumber(state.policyNumber);
    }
  }, [dispatch]);

  useEffect(() => {
    if (policyNumber) {
      dispatch(getBasicInfo(policyNumber));
      dispatch(getAgentInfo(policyNumber));
      return () => dispatch(resetPolicydetails());
    }
  }, [policyNumber, dispatch]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(hideErrorMessage());
      }, 300);
    }
  }, [error]);

  useEffect(() => {
    if (year !== "") {
      dispatch(getPaymentInfo(policyNumber, year));
    }
  }, [year, dispatch]);

  useEffect(() => {
    if (getPaymentInfoDetails) {
      console.log("payment info: ", getPaymentInfoDetails);
    }
  }, [getPaymentInfoDetails]);

  const handleDropDownChange = (event) => {
    setPolicyNumber(event.target.value);
    setExpanded();
  };

  const handlePremiumChange = (event) => {
    setYear(event.target.value);
    console.log("statement year: ", year);
  };

  const commmenceYearDate = basicInfoData.commencementDate;

  return (
    <>
      <ContainerHeader match={match} />
      {/* <Link to='my-policies'><i className="zmdi zmdi-arrow-left zmdi-hc-fw zmdi-hc-lg mb-4" />Back</Link> */}
      <ControlledOpenSelect
        data={state ? state.dropDownData : []}
        policyNumber={policyNumber}
        handleChange={handleDropDownChange}
      />
      <div className={classes.root}>
        <ExpansionPanel
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            className="details-card-head-text"
          >
            <Typography className={classes.heading}>
              Basic Information
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: "100%" }} className="row custom-info-row">
              <BasicInfo
                data={basicInfoData}
                agentInfo={agentInfoData}
                loading={loading}
              />
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            className="details-card-head-text"
          >
            <Typography className={classes.heading}>
              Benefit Information
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="row custom-info-row">
              {benifitInfoData && benifitInfoData.length
                ? benifitInfoData.map((info, j) => (
                    <BenefitInformation key={j} data={info} />
                  ))
                : null}
              {!loading && !benifitInfoData.length ? (
                <p className="text-grey ml-3">No data found</p>
              ) : null}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            className="details-card-head-text"
          >
            <Typography className={classes.heading}>
              Beneficiaries/Others Information
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="custom-info-row" style={{ width: "100%" }}>
              {partyInfoData
                ? partyInfoData.map((data, i) => (
                    <BeneficiariesInfo key={i} data={data} />
                  ))
                : null}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            className="details-card-head-text"
          >
            <Typography className={classes.heading}>
              Financial Information
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="row custom-info-row">
              <FinancialInformation data={financialInfoData} />
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            className="details-card-head-text"
          >
            <Typography className={classes.heading}>
              Claim Information
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="row custom-info-row">
              {claimInfoData && claimInfoData.length
                ? claimInfoData.map((info, j) => (
                    <ClaimInformation key={j} data={info} />
                  ))
                : null}
              {!loading && !claimInfoData.length ? (
                <p className="text-grey ml-3">
                  Claim info will be available only when claims (Funeral/ Death/
                  Hospitalization etc.,) registered on this policy.
                </p>
              ) : null}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            className="details-card-head-text"
          >
            <Typography className={classes.heading}>
              Customer Service Information
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="row custom-info-row" style={{ width: "100%" }}>
              <CustomerServiceInfo
                data={customerServiceInfoData}
                loading={loading}
              />
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            className="details-card-head-text"
          >
            <Typography className={classes.heading}>
              Premium Payments Statement
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="row custom-payment-mobile">
              <YearsDropdown
                data={state ? state.dropDownData : []}
                startYear={commmenceYearDate}
                year={year}
                handlePremiumChange={handlePremiumChange}
              />
              <div className="col-lg-12 col-sm-12 col-12">
                <PremiumStatement data={getPaymentInfoDetails} />
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          expanded={expanded === "panel8"}
          onChange={handleChange("panel8")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            className="details-card-head-text"
          >
            <Typography className={classes.heading}>
              Transaction History
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="row custom-info-row">
              <TransactionHistory data={payHistory} />
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        {loadingCircle && (
          <div
            className="loading-view custom-policy-loader"
            style={{ zIndex: 999 }}
          >
            <CircularProgress />
          </div>
        )}

        {loading && (
          <div
            className="loading-view custom-policy-loader"
            style={{ zIndex: 999 }}
          >
            <CircularProgress />
          </div>
        )}

        {error && <ErrorToaster message={error} />}
        <ToastContainer />
      </div>
    </>
  );
};

ControlledExpansionPanels.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ControlledExpansionPanels);
