import {
  DPO_REQUEST_SUCCESS,
  DPO_REQUEST_SUCCESS_MESSAGE,
  HIDE_DPO_REQUEST_ERROR_MESSAGE,
  HIDE_DPO_REQUEST_SUCCESS_MESSAGE,
  DPO_REQUEST_LOADER,
  DPO_REQUEST_ERROR_MESSAGE,
  DPO_REQUEST_RESET,
} from "constants/ActionTypes";

const INIT_STATE = {
  dpoLoader: false,
  dpoAlertMessage: '',
  dpoShowSuccess: false,
  dpoShowMessage: false,
  dpoPayData: '',
  dpoPayStatus: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case DPO_REQUEST_SUCCESS: {
      return {
        ...state,
        dpoLoader: false,
        dpoPayStatus: action.payload,
      };
    }

    case DPO_REQUEST_SUCCESS_MESSAGE: {
      return {
        ...state,
        dpoLoader: false,
        dpoShowSuccess: true,
        dpoPayData: action.payload,
      }
    }

    case DPO_REQUEST_ERROR_MESSAGE: {
      return {
        ...state,
        dpoAlertMessage: action.payload,
        dpoShowMessage: true,
        dpoLoader: false,
      };
    }
    case HIDE_DPO_REQUEST_ERROR_MESSAGE: {
      return {
        ...state,
        dpoAlertMessage: "",
        dpoShowMessage: false,
        dpoLoader: false,
        dpoPayStatus: null,
      };
    }

    case HIDE_DPO_REQUEST_SUCCESS_MESSAGE: {
      return {
        ...state,
        dpoShowSuccess: false,
        dpoLoader: false,
        dpoPayData: '',
        dpoPayStatus: null,
      };
    }

    case DPO_REQUEST_LOADER: {
      return {
        ...state,
        dpoLoader: true,
      };
    }

    case DPO_REQUEST_RESET: {
      return {
        ...state,
        ...INIT_STATE,
      };
    }

    case "resetLoader": {
      return {
        ...state,
        dpoLoader: false,
      };
    }

    default:
      return state;
  }
};