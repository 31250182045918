/* eslint-disable no-useless-concat */
import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { history } from 'store';
import moment from 'moment';
import { currencyConverter } from 'util/currencyConverter';
import { SessionTimeout } from '../../MyClaims/SessionTimeout/SessionTimeout';
import { getDpoTokenData, requestDpoTokenReset } from "actions/DPOToken";
import { getDpoRequestData, requestDpoLoader, requestDpoReset, showRequestDpoError } from "actions/DPOPayment";
import { SuccessToaster, ErrorToaster } from 'util/ReactToastify';
import Button from "@material-ui/core/Button";
import { httpGet, httpPost } from "util/REST";
import PaymentDialog from './PaymentDialog';
import PaymentPage from './PaymentPage';



const PolicySection = ({ userCountryCode, userContactNumber, openPayMessage, condition, history, openLoader, closeLoader, dropDownData, data: {
  policyNumber,
  nextDueDate,
  policyHolderName,
  policyName,
  policyStatus,
  premium,
  getContactNumber,
  roleType
} }) => {

  const { dpoTokenAlertMessage, dpoTokenData, dpoTokenStatus } = useSelector(({ dpoTokenReducer }) => dpoTokenReducer)

  const dispatch = useDispatch()

  const [amount, setAmount] = useState(premium);
  const [show, setShow] = useState(false);
  const [token, setToken] = useState('');
  const [openPayment, setOpenPayment] = useState(false);
  const [payStatus, setPayStatus] = useState();
  const [status, setStatus] = useState()
  const [currency, setCurrency] = useState('');
  const [open, setOpen] = useState(false);
  const [tokenError, setTokenError] = useState(false);
  const [error, setError] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [tokenErrorMessage, setTokenErrorMessage] = useState("");
  const [collectPayment, setCollectPayment] = useState(false)
  const [process, setProcess] = useState(false)

  //const repsonse = 'TransID=0D734B0B-4A54-4BB5-B37A-4BD2E575F2B6&CCDapproval=4444444432&PnrID=4000887501&TransactionToken=0D734B0B-4A54-4BB5-B37A-4BD2E575F2B6&CompanyRef=4000887501'


  const resetValues = () => {
   
  }

  

  const handlePaymentOpen = async () => {
    console.log("item: ", {
      policyNumber,
      nextDueDate,
      policyHolderName,
      policyName,
      policyStatus,
      premium,
    })
    setOpen(true)
  }

  const verify = () => {
    dispatch(requestDpoLoader())
    dispatch(getDpoRequestData(policyNumber))
  }

  const handlePaymentClose = () => {
    setAmount(`${premium}`)
    console.log("premium: ", premium)
    setOpen(false);
  }

  const closePayment = () => {
    //dispatch(requestDpoTokenReset())
    setProcess(false)
    setOpenPayment(false)
    console.log("payment dialog: ",openPayment)
    console.log("cleared token data: ",dpoTokenData)
    verify()
  }

  const completePayment = () => {
    setProcess(false)
    setOpenPayment(false)
    verify()
  }

  const pushPayment = () => {
    dispatch(requestDpoTokenReset())
    setOpen(false)
    handlePayment()
    setProcess(true)
  }

  const changeAmount = (e) => {
    setAmount(e.target.value);
  }

  const openPaymentPage = () => {
    console.log("opened token data: ",dpoTokenData)
    setOpenPayment(true)
  }

  const handlePayment = () => {

    dispatch(getDpoTokenData(policyNumber,amount))
    
  }

  useEffect(() => {

    console.log("amount: ", amount)

    const getCustomerDetails = async () => {
      await httpGet(`getCustomerDetailsOnlinePayment?policyNumber=${policyNumber}`)
        .then(data => {
          const dataResponse = data.response;
          setCurrency(dataResponse.productCurrency)
        }).catch((error) => {
          console.log(error);
        });
    }

    getCustomerDetails()
  }, [])

  useEffect(() => {

    if (dpoTokenData && process) {
      console.log("token status: ",dpoTokenStatus)
      openPaymentPage()
      console.log('payment page open')
    }

  }, [dpoTokenData, process])

  /*
  useEffect(() => {
    if(collectPayment){
      verify()
    }
  },[collectPayment])
  */

  return (

    <>

      <SessionTimeout />

      <PaymentPage open={openPayment}
        dpoUrl={`https://secure.3gdirectpay.com/payv3.php?ID=${dpoTokenData}`}
        closePayment={closePayment}
        completePayment={completePayment}
      />

      <PaymentDialog open={open}
        pushPayment={pushPayment}
        handlePaymentClose={handlePaymentClose}
        policyName={policyName}
        policyHolderName={policyHolderName}
        policyNumber={policyNumber}
        currency={currency}
        premiumAmount={amount}
        changeAmount={(e) => setAmount(e.target.value)}
      />

      <div className="row">
        <div className="col">
          <div className="policy-data">
            <div className="card policy-card">
              <div className="card-body">

                <div className="row">

                  <div className='col-md-7' onClick={() => history.push("/home/my-policies/policy-details", { policyNumber, dropDownData })}>

                    <div className="name">
                      <p className=" mr-2 policies-section label"><b>Product Name :</b></p>
                      <p className=" policies-section">{policyName}</p>
                    </div>

                    <div className="name">
                      <p className=" mr-2 policies-section label"><b>Policy Number :</b></p>
                      <p className=" policies-section">{policyNumber}</p>
                    </div>

                    <div className="name">
                      <p className=" mr-2 policies-section label"><b>Premium Amount :</b></p>
                      <p className="policies-section">{premium}</p>
                    </div>

                  </div>

                  <div className="col-md-4">

                    <div className="name">
                      <p className=" mr-2 policies-section label"><b>Policy Holder :</b></p>
                      <p className="policies-section">{policyHolderName}</p>
                    </div>

                    <div className="name">
                      <p className=" mr-2 policies-section label"><b>Policy Status :</b></p>
                      <p className="policies-section">{policyStatus}</p>
                    </div>


                    <div className="name">
                      <p className=" mr-2 policies-section label"><b>Due From :</b></p>
                      <p className="policies-section">{nextDueDate ? moment(nextDueDate).format('DD/MM/YYYY') : null}</p>
                    </div>

                  </div>

                </div>

                <div className="pay-button-holder">

                  <div className="btn-con pay-button">
                    <Button onClick={handlePaymentOpen} variant="contained">
                      Pay
                    </Button>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}


export default withRouter(PolicySection);
//mt-1 mb-2 d-flex justify-content-center align-items-right