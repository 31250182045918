import React from "react";
import { toast } from "react-toastify";

const options = {
  autoClose: 3000,
  hideProgressBar: true,
  className: "toaster-text",
  toastId: "",
};
export const ErrorToaster = ({ message }) => {
  return <div>{toast.error(message, options)}</div>;
};

export const SuccessToaster = ({ message }) => {
  return <div>{toast.success(message, options)}</div>;
};

export const toastMsgs = {
  success: (message) => toast.success(message, options),
  error: (message) => toast.error(message, options),
};

// import React from "react";
// import { ToastContainer as TC, toast as t } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// export const toast = {
//   success: (message) => t.success(message, options),
//   error: (message) => t.error(message, options),
// };

// export const ToastContainer = () => <TC />;

// Usage Example
// import { toast , ToastContainer } from "util/Toaster";
// { error && toast.error(error); }
// <ToastContainer />;
