import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

const PaymentPage = ({ dpoUrl, open, closePayment, completePayment }) => {
  return (
    <Dialog fullScreen fullWidth open={open} className="custom-confirm-dialog">
      <DialogContent className="custom-details">
        <div
          style={{ position: "relative", width: "100%", height: "100%" }}
          className="payment-page"
        >
          <iframe
            src={dpoUrl}
            title={"DPO Payments"}
            width={"100%"}
            height={"100%"}
          ></iframe>
        </div>
      </DialogContent>

      <DialogActions className="confirm-btns">
        <Button onClick={closePayment} className="no">
          Cancel
        </Button>
        <Button onClick={completePayment} className="yes">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentPage;
